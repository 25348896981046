import React, {useEffect} from 'react';
import {useFormikContext} from "formik";
import alphabeticSort from "@utils/filteringMethods/alphabeticSort";

const FormObserver = ({defaultValues, setValuesChanged, inappIsActive, valuesSavedRef}) => {
    const {values} = useFormikContext();

    const removeExtraFields = (curVal) => {
        Object.entries(curVal.titles).map((item) => {
            if (!item[1]) delete curVal.titles[item[0]]
        })
        delete curVal.languageCode;
    }

    useEffect(() => {
        let curVal = structuredClone(values);
        let defVal = structuredClone(defaultValues);
        if(inappIsActive) {
            valuesSavedRef.current = curVal;
        }
        //Сортирую тайтлы и дескрипшены перед сравнением,
        // т.к. во время редактирования они могли поменяться местами
        if (curVal.titles && curVal.descriptions) {
            curVal.titles = alphabeticSort(curVal.titles);
            curVal.descriptions = alphabeticSort(curVal.descriptions);
            defVal.titles = alphabeticSort(defVal.titles);
            defVal.descriptions = alphabeticSort(defVal.descriptions);

            removeExtraFields(curVal);
        }
        setValuesChanged(JSON.stringify(curVal) !== JSON.stringify(defVal))
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};

export default FormObserver;