import React, {useEffect} from 'react';
import {getStorageItem, removeStorageItem} from "@utils/helpers/storageUtils";
import {CURRENT_EDIT_INAPP} from "@constants/StorageKeys";

const UseBeforeUnload = () => {
    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            if(getStorageItem(CURRENT_EDIT_INAPP, 'sessionStorage')) {
                removeStorageItem(CURRENT_EDIT_INAPP, 'sessionStorage');
            }
            return "";
        };

        const handleBeforeUnload = () => {
            window.addEventListener("beforeunload", unloadCallback);
        };

        // Проверяем текущий URL
        const currentUrl = window.location.pathname;
        if (currentUrl !== "/login") {
            handleBeforeUnload();
        }
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);
};

export default UseBeforeUnload;
